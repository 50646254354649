
export default {
  data: () => ({
    icons: [
      { name: "mdi-instagram", link: "https://www.instagram.com/matjrah_app/" },
      { name: "mdi-twitter", link: "https://twitter.com/matjrah_app" },
      {
        name: "mdi-whatsapp",
        link: "https://api.whatsapp.com/send?phone=966555252984",
      },
    ],
  }),
  computed: {
    links() {
      return [
        { name: this.$t('sellOnInstagram'), to: "/instagram" },
        { name: this.$t('affiliateProgram'), to: "/affiliate" },
        { name: this.$t('posSystem'), to: "/pos" },
        { name: this.$t('faqs'), to: "/faqs" },
        { name: this.$t('createFullStore'), to: "/full-store" },
      ];
    },
    linksKW() {
      return [
        { name: this.$t('sellOnInstagram'), to: "/instagram" },
        { name: this.$t('faqs'), to: "/faqs" },
        { name: this.$t('createFullStore'), to: "/full-store" },
      ];
    },
    otherLinks() {
      return [
        { name: this.$t('termsAndConditions'), to: "/terms-and-conditions" },
        { name: this.$t('privacyPolicy_'), to: "/privacy-policy" },
        { name: this.$t('helpCenter'), href: "https://help.matjrah.com" },
        {
          name: this.$t('platformStatus'),
          href: "https://stats.uptimerobot.com/Vr6DYs2YWo",
        },
        { name: this.$t('comparisonWithSallaAndZid'), to: "/matjrah-vs-salla-zid" },
      ];
    },
  },
};
